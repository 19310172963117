import React from 'react';
import styled from 'styled-components';

import { media, fonts } from 'src/views/theme';
import {
  IconMenuWidget as IProps,
} from 'infrastructure/gatsby/types/icon-menu';
import {
  jetBlack,
  paleGrey,
} from '@shipae/components-sandbox/component/colors';
import SmartLink from 'components/smart-link';

const Wrapper = styled.section`
  background: ${ paleGrey() };
`;

const Container = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 100%;
  max-width: var(--container-width);

  ${ media.sm } {
    display: none;
  }
`;

const IconLink = styled(SmartLink)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 3.2rem 2rem;
  text-decoration: none;
`;

const Icon = styled.img`
  ${ media.md } {
    width: 4rem;
  }
`;

const IconLabel = styled.h2`
  padding-top: 2.4rem;
  text-transform: uppercase;
  color: ${ jetBlack() };
  ${ fonts.bodys300 }
`;

const IconMenu: React.FC<IProps> = ({
  sectionId,
  icons,
}) => (
  <Wrapper id={sectionId}>
    <Container>
      {icons.map((icon) => (
        <IconLink key={icon.id} href={icon.url || '#'}>
          <Icon
            src={icon.icon?.localFile?.publicURL}
            alt={icon.icon?.alternativeText || ''}
            loading="lazy"
          />
          <IconLabel>{icon.label || 'No label'}</IconLabel>
        </IconLink>
      ))}
    </Container>
  </Wrapper>
);

export default IconMenu;
